import { template as template_25ae4af5893e459a8c1332168ab8469f } from "@ember/template-compiler";
const WelcomeHeader = template_25ae4af5893e459a8c1332168ab8469f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
